import React from "react";
import { useTranslation } from "react-i18next";

export const ComponentHomeClubProfitability = ({ formLink }) => {
    const { t } = useTranslation();

    const scrollToSection = () => {
        const section = document.getElementById(formLink);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <div className="bg-[#43ec9b0d] w-full text-secondary pt-36 sm:pt-[230px] lg:pt-[140px] xl:pt-[230px] 2xl:pt-[270px] px-4 md:px-16 pb-20">
            <div className="w-full mx-auto mt-10">
                <p className="text-xl font-medium text-center mb-10">
                    {t("HomeClubProfit.first.p-1")}
                </p>
                <img
                    src="/images/club/profitclubs.png"
                    className="w-full max-w-[620px] mx-auto"
                    alt="clubs logo"
                />
                <div className="flex flex-col items-center gap-5">
                    <h2 className="mt-24 text-center text-3xl lg:text-[45px] lg:leading-[55px] font-semibold max-w-[850px]">
                        {t("HomeClubProfit.first.title")}
                    </h2>
                    <p className="text-xl text-center max-w-[858px]">
                        {t("HomeClubProfit.first.p-2")}
                    </p>
                    <div className="flex flex-wrap items-center justify-center gap-3 my-4">
                        <button
                            onClick={scrollToSection}
                            className="w-[200px] xl:w-[237px] bg-primary text-lg lg:text-xl font-medium text-[#373737] h-[55px] xl:h-[71px] rounded-full flex items-center justify-center hover:bg-secondary hover:text-white transform duration-200"
                        >
                            {t("HomeClubProfit.demo")}
                        </button>
                        <button
                            onClick={scrollToSection}
                            className="w-[200px] xl:w-[237px] bg-white text-lg lg:text-xl font-medium text-[#373737] h-[55px] xl:h-[71px] rounded-full flex items-center justify-center border-2 border-secondary hover:bg-secondary hover:text-white transform duration-200"
                        >
                            {t("HomeClubProfit.crm")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-9 max-w-fit mx-auto w-full mt-16">
                <div className="bg-secondary text-white w-full max-w-[445px] min-h-[405px] h-full px-5 text-center flex flex-col gap-5 items-center rounded-[30px] py-[30px]">
                    <img
                        src="/images/club/profitstats.png"
                        className="w-[79px] h-[60px]"
                        alt="stats"
                    />
                    <p className="text-[25px] font-medium">
                        {t("HomeClubProfit.Card-1.p-1")}
                    </p>
                    <div className="bg-gray-600 text-gray-600 h-[1px] w-full" />
                    <p className="text-xl text-[#FFFFFFC2]">
                        {t("HomeClubProfit.Card-1.p-2")}
                    </p>
                </div>
                <div className="bg-secondary text-white w-full max-w-[445px] min-h-[405px] h-full px-5 text-center flex flex-col gap-5 items-center rounded-[30px] py-[30px]">
                    <img
                        src="/images/club/profitmanagement.png"
                        className="w-[68px] h-[60px]"
                        alt="management"
                    />
                    <p className="text-[25px] font-medium">
                        {t("HomeClubProfit.Card-2.p-1")}
                    </p>
                    <div className="bg-gray-600 text-gray-600 h-[1px] w-full" />
                    <p className="text-xl text-[#FFFFFFC2]">
                        {t("HomeClubProfit.Card-2.p-2")}
                    </p>
                </div>
                <div className="bg-secondary text-white w-full max-w-[445px] min-h-[405px] h-full px-5 text-center flex flex-col gap-5 items-center rounded-[30px] py-[30px]">
                    <img
                        src="/images/club/profitcommunity.png"
                        className="w-[72px] h-[60px]"
                        alt="community"
                    />
                    <p className="text-[25px] font-medium">
                        {t("HomeClubProfit.Card-3.p-1")}
                    </p>
                    <div className="bg-gray-600 text-gray-600 h-[1px] w-full" />
                    <p className="text-xl text-[#FFFFFFC2]">
                        {t("HomeClubProfit.Card-3.p-2")}
                    </p>
                </div>
                <div className="bg-secondary text-white w-full max-w-[445px] min-h-[405px] h-full px-5 text-center flex flex-col gap-5 items-center rounded-[30px] py-[30px]">
                    <img
                        src="/images/club/profitinsights.png"
                        className="w-[65px] h-[60px]"
                        alt="insights"
                    />
                    <p className="text-[25px] font-medium">
                        {t("HomeClubProfit.Card-4.p-1")}
                    </p>
                    <div className="bg-gray-600 text-gray-600 h-[1px] w-full" />
                    <p className="text-xl text-[#FFFFFFC2]">
                        {t("HomeClubProfit.Card-4.p-2")}
                    </p>
                </div>
                <div className="bg-secondary text-white w-full max-w-[445px] min-h-[405px] h-full px-5 text-center flex flex-col gap-5 items-center rounded-[30px] py-[30px]">
                    <img
                        src="/images/club/profitrevenue.png"
                        className="w-[65px] h-[60px]"
                        alt="revenue"
                    />
                    <p className="text-[25px] font-medium">
                        {t("HomeClubProfit.Card-5.p-1")}
                    </p>
                    <div className="bg-gray-600 text-gray-600 h-[1px] w-full" />
                    <p className="text-xl text-[#FFFFFFC2]">
                        {t("HomeClubProfit.Card-5.p-2")}
                    </p>
                </div>
                <div className="bg-secondary text-white w-full max-w-[445px] min-h-[405px] h-full px-5 text-center flex flex-col gap-5 items-center rounded-[30px] py-[30px]">
                    <img
                        src="/images/club/profitoperations.png"
                        className="w-[65px] h-[60px]"
                        alt="operations"
                    />
                    <p className="text-[25px] font-medium">
                        {t("HomeClubProfit.Card-6.p-1")}
                    </p>
                    <div className="bg-gray-600 text-gray-600 h-[1px] w-full" />
                    <p className="text-xl text-[#FFFFFFC2]">
                        {t("HomeClubProfit.Card-6.p-2")}
                    </p>
                </div>
            </div>
        </div>
    );
};
